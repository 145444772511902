const Menu = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="2.40002" y="4.99902" width="19.2" height="1.8" rx="0.9" />
      <rect x="2.40002" y="11.5991" width="19.2" height="1.8" rx="0.9" />
      <rect x="2.40002" y="18.1992" width="19.2" height="1.8" rx="0.9" />
    </svg>
  )
}

export default Menu
